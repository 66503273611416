import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationResponse } from "swr/mutation";
import { HTTP_METHODS } from "rivals/services/base";
import { KEY as MUTATE_KEY } from "rivals/services/getCurrentUser/useGetCurrentUser";
import {
  DISMISSED_ENABLE_NOTIFICATION_KEY,
  DISMISSED_INSTALL_PWA_KEY
} from "rivals/shared/constants";

const KEY = "/api/user/sign_out";

// https://swr.vercel.app/docs/mutation#revalidation
export default function useLogoutUser(
  callback?: () => void
): SWRMutationResponse {
  const { mutate } = useSWRConfig();
  return useSWRMutation(
    KEY,
    async function (url) {
      await fetch(url, {
        method: HTTP_METHODS.DELETE
      });
    },
    {
      onSuccess: function () {
        localStorage.removeItem(DISMISSED_INSTALL_PWA_KEY);
        localStorage.removeItem(DISMISSED_ENABLE_NOTIFICATION_KEY);
        mutate(MUTATE_KEY);
        callback && callback();
      }
    }
  );
}
