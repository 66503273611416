import { RivalsAppProps, RivalsPageProps } from "rivals/Context";
import ErrorPage from "rivals/components/pages/error/ErrorPage";
import { RESPONSE_PAGE_NOT_FOUND_ERROR } from "rivals/services/base";

function NotFound(): JSX.Element {
  return (
    <main role="main">
      <ErrorPage userError={true} />
    </main>
  );
}

NotFound.getInitialProps = async ({
  res,
  isServer
}: RivalsAppProps): Promise<RivalsPageProps> => {
  if (res) {
    res.statusCode = RESPONSE_PAGE_NOT_FOUND_ERROR;
  }

  return {
    isServer
  };
};

export default NotFound;
