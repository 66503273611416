export default {
  fumbleError: "Oops! We fumbled.",
  fumblePrompt1: "We'll work on fixing it. Please go to ",
  fumblePrompt2: " or wait a minute and then try again.",
  homepageLink: "Rivals.com",
  offsidesError: "Offsides!",
  offsidesPrompt1: "The page you're trying to find doesn't exist. Go to ",
  offsidesPrompt2: " to find what you're looking for.",
  resetPrompt1: "Seeing this page repeatedly? Try clicking ",
  resetPrompt2:
    " to run a troubleshooting tool that will log you out. Please sign in again after."
};
