import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import { FunctionComponent, ReactElement } from "react";
import CustomButton from "rivals/components/shared/CustomButton";
import RivalsLink from "rivals/components/shared/Link";
import { RESPONSE_INTERNAL_SERVICE_ERROR } from "rivals/services/base";
import useLogoutUser from "rivals/services/logoutUser/useLogoutUser";
import { PATHS, RIVALS_USER_COOKIES } from "rivals/shared/constants";
import { setData } from "rivals/shared/utils/qa";
import styles from "./ErrorPage.module.scss";
import strings from "./strings";
import { OwnProps } from "./types";

const {
  fumbleError,
  fumblePrompt1,
  fumblePrompt2,
  homepageLink,
  offsidesError,
  offsidesPrompt1,
  offsidesPrompt2,
  resetPrompt1,
  resetPrompt2
} = strings;

const ErrorPage: FunctionComponent<OwnProps> = ({
  userError = false,
  statusCode = RESPONSE_INTERNAL_SERVICE_ERROR
}): ReactElement => {
  let pageImage, errorText, errorPrompt1, errorPrompt2;
  const router = useRouter();

  // SWR callback on successful logout
  const onLogoutUser = (): void => {
    RIVALS_USER_COOKIES.forEach(cookie => Cookies.remove(cookie));
    router.push(PATHS.SIGN_OUT);
    // eslint-disable-next-line no-console
    console.info(
      `ResetFumbleLink clicked: ${userError}_statusCode_${statusCode}`
    );
  };

  const { trigger } = useLogoutUser(onLogoutUser);

  const resetSession = (): void => {
    trigger();
  };

  if (userError) {
    pageImage = <div className={styles.whistle}></div>;
    errorText = offsidesError;
    errorPrompt1 = offsidesPrompt1;
    errorPrompt2 = offsidesPrompt2;
  } else {
    pageImage = <div className={styles.football}></div>;
    errorText = fumbleError;
    errorPrompt1 = fumblePrompt1;
    errorPrompt2 = fumblePrompt2;
  }

  const sessionReset = (
    <div className={styles.resetPrompt}>
      {resetPrompt1}
      <CustomButton
        ariaLabel="search toggle"
        buttonText="here"
        className={styles.resetLink}
        onClick={resetSession}
        philter="fumble-page-session-reset"
      />
      {resetPrompt2}
    </div>
  );

  return (
    <div className="error-page">
      <div className={styles.errorPage} {...setData("fumble-page", "")}>
        {pageImage}
        <div className={styles.errorText} {...setData("fumble-page", "error")}>
          {errorText}
        </div>
        <div
          className={styles.errorPrompt}
          {...setData("fumble-page", "prompt")}
        >
          {errorPrompt1}
          <RivalsLink
            className={styles.linkStyles}
            data="fumble-page-home-url"
            href={PATHS.HOME}
          >
            {homepageLink}
          </RivalsLink>
          {errorPrompt2}
        </div>
        {sessionReset}
      </div>
    </div>
  );
};

export default ErrorPage;
